import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UpdateUserRequestDto, UserResponseDto } from 'src/types/api/user';
import { Col, Form, FormGroup, Row } from 'reactstrap';
import TextInput from 'src/components/Form/TextInput';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import DatePickerInput from 'src/components/Form/DatePickerInput';
import { success } from 'src/services/toastr';
import PhoneNumberInput from 'src/components/Form/PhoneNumberInput';
import SaveButton from 'src/components/Form/SaveButton';
import SelectCountryInput from 'src/components/Form/Select/SelectCountryInput';
import SelectInvestorExperienceLevelTypeInput from 'src/components/Form/Select/SelectExperienceLevelInput';

interface Props {
  user: UserResponseDto;
  onUpdate: (request: UpdateUserRequestDto) => Promise<UserResponseDto | void>;
}

const UserEdit: React.FC<Props> = ({ user, onUpdate }) => {
  const { t } = useTranslation();
  const [updateUser, setUpdateUser] = useState<UpdateUserRequestDto>(user);
  const [countriesTaxes, setCountriesTaxes] = useState<{ [key: string]: number }>({});
  const defaultCountryTaxAmount = 15;
  const UserEditSchema = Yup.object().shape({
    email: Yup.string().required().email(),
    proxy_details: Yup.object().nullable().shape({
      proxy_start_date: Yup.date().optional().nullable(),
      proxy_valid_until: Yup.date().optional().nullable(),
      proxy_number: Yup.string().optional().nullable(),
    }),
  });

  const onSubmit = useCallback(
    async (request: UpdateUserRequestDto, helpers: FormikHelpers<UpdateUserRequestDto>) => {
      try {
        await onUpdate(request);
        success(t('common.updated_success'));
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }

      return true;
    },
    [onUpdate, t],
  );

  return (
    <React.Fragment>
      <Formik
        initialValues={updateUser}
        enableReinitialize={true}
        validationSchema={UserEditSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Row>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'email'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'first_name'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'middle_name'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'last_name'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <PhoneNumberInput name={'phone'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <DatePickerInput name={'dob'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'gov_code'} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectCountryInput
                    name={'residence'}
                    setCountriesTaxes={setCountriesTaxes}
                    onChange={(e: any) => {
                      setUpdateUser({
                        ...updateUser,
                        residence: e.value,
                        personal_income_tax:
                          countriesTaxes[e.value] !== undefined
                            ? countriesTaxes[e.value]
                            : defaultCountryTaxAmount,
                      });
                    }}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'city'} placeholder={t('users.city')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'street'} placeholder={t('users.street')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput name={'house_number'} placeholder={t('users.house_number')} />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <TextInput
                    name={'personal_income_tax'}
                    placeholder={t('users.personal_income_tax')}
                  />
                </Col>
                <Col sm={3} className={'mb-4'}>
                  <SelectInvestorExperienceLevelTypeInput
                    name={'investor_properties.investor_experience_level'}
                    placeholder={t('users.investor_experience_level')}
                  />
                </Col>
              </Row>
              {user?.show_proxy_details && (
                <>
                  <hr />
                  <Row>
                    <Col sm={3} className={'mb-4'}>
                      <DatePickerInput
                        name={'proxy_details.proxy_start_date'}
                        placeholder={t('users.proxy_details.proxy_start_date')}
                      />
                    </Col>
                    <Col sm={3} className={'mb-4'}>
                      <DatePickerInput
                        name={'proxy_details.proxy_valid_until'}
                        placeholder={t('users.proxy_details.proxy_valid_until')}
                      />
                    </Col>
                    <Col sm={3} className={'mb-4'}>
                      <TextInput
                        name={'proxy_details.proxy_number'}
                        placeholder={t('users.proxy_details.proxy_number')}
                      />
                    </Col>
                  </Row>
                </>
              )}
              {console.log(errors, 'DEBUG')}
              <div className={'mt-4 mb-4'}>
                <SaveButton title={t('common.submit')} submitting={isSubmitting} />
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UserEdit;
