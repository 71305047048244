import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { setGlobalLoading } from 'src/modules/app/actions';
import Api from 'src/api';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import CopyOnClick from 'src/components/CopyOnClick';
import LegalEntityView from 'src/components/Views/LegalEntityView';
import UserTypeView from 'src/components/Views/UserTypeView';
import { Permissions } from 'src/helpers/auth/permissions';
import PermissionAction from 'src/components/PermissionAction';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ReasonModal from 'src/components/Modal/Modals/ReasonModal';
import ActionModal from 'src/components/Modal/Modals/ActionModal';
import { UserCompanyAddressResponseDto, UserCompanyResponseDto } from 'src/types/api/userCompanies';
import VerifiedBadge from 'src/pages/User/Badges/VerifiedBadge';
import TabData, { BaseTabProps, TabInfo } from 'src/components/Tabs';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import CompanyContext from 'src/pages/User/Update/Company/CompanyContext';
import { CompanyAddressTypeEnum } from 'src/helpers/Enums/CompanyAddressTypeEnum';
import PrimaryButton from 'src/components/Form/PrimaryButton';
import CompanyEdit from 'src/pages/User/Update/Company/CompanyEdit';
import {
  TabLegal,
  TabDocuments,
  TabRelatedAccounts,
  TabActions,
  TabInvestments,
  TabAgreements,
  TabComments,
  TabWalletOperations,
  TabLemonwayDocuments,
  TabAutoInvestorActions,
} from 'src/pages/User/Update/Company/Tabs';
import { RouteList } from 'src/routes';
import FormModal from 'src/components/Modal/Modals/FormModal';
import CustomNotificationForm from '../CustomNotificationForm';
import { LegalEntityClassEnum } from 'src/helpers/Enums/LegalEntityClassEnum';
import TabWalletP2POperations from 'src/pages/User/Update/Company/Tabs/TabWalletP2POperations';
import DirectMarketingBadge from 'src/pages/User/Badges/DirectMarketingBadge';
import CountryLabel from 'src/components/Labels/CountryLabel';
import { UserTypeEnum } from 'src/helpers/Enums/UserTypeEnum';
import { StringParam, useQueryParam } from 'use-query-params';
import TabAML from './Tabs/TabAML';
import { navigate } from 'src/helpers/navigate';
import AddAffiliateAmbassadorModal from 'src/components/Modal/Modals/AddAffiliateAmbassadorModal';
import EditAffiliateAmbassadorModal from 'src/components/Modal/Modals/EditAffiliateAmbassadorModal';
import AssignManagerModal from 'src/components/Modal/Modals/AssignManagerModal';
import { success } from 'src/services/toastr';
import AddAccreditationDialog from 'src/pages/User/Update/Company/Dialogs/AddAccreditationDialog';

const Tabs: TabInfo<BaseTabProps>[] = [
  {
    name: 'users.documents',
    iconClass: 'far fa-file',
    component: TabDocuments,
    permission: Permissions.BO__USERS__VIEW_DOCUMENTS,
  },
  {
    name: 'users.lemonway_documents',
    iconClass: 'far fa-file',
    component: TabLemonwayDocuments,
    permission: Permissions.BO__USERS__VIEW_LEMONWAY_DOCUMENTS,
  },
  {
    name: 'users.actions',
    iconClass: 'fas fa-history',
    component: TabActions,
    permission: Permissions.BO__USERS__VIEW_ACTIONS,
  },
  {
    name: 'users.agreements',
    iconClass: 'fas fa-handshake',
    component: TabAgreements,
    permission: Permissions.BO__USERS__VIEW_AGREEMENTS,
  },
  {
    name: 'users.related_accounts',
    iconClass: 'fas fa-euro-sign',
    component: TabRelatedAccounts,
    permission: Permissions.BO__USERS__VIEW_RELATED_ACCOUNTS,
  },
  {
    name: 'users.company.legal',
    iconClass: 'fas fa-euro-sign',
    component: TabLegal,
    permission: Permissions.BO__USERS__VIEW,
  },
  {
    name: 'users.comments',
    iconClass: 'fas fa-comment-dots',
    component: TabComments,
    permission: Permissions.BO__USERS__VIEW_COMMENTS,
  },
  {
    name: 'users.wallet_operations',
    iconClass: 'fas fa-wallet',
    component: TabWalletOperations,
    permission: Permissions.BO__USERS__VIEW_WALLET_MONEY_OPERATIONS,
  },
  {
    name: 'users.wallet_p2p_operations',
    iconClass: 'fas fa-exchange-alt',
    component: TabWalletP2POperations,
    permission: Permissions.BO__USERS__VIEW_WALLET_P2P_OPERATIONS,
  },
  {
    name: 'users.aml',
    iconClass: 'fas fa-shield-alt',
    component: TabAML,
    permission: Permissions.BO__USERS__VIEW_AML,
  },
];

const Company: React.FC = () => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [tab] = useQueryParam('tab', StringParam);
  const [company, setCompany] = useState<UserCompanyResponseDto>();
  const [residentialAddress, setResidentialAddress] = useState<
    UserCompanyAddressResponseDto | undefined
  >();
  const [contactAddress, setContactAddress] = useState<UserCompanyAddressResponseDto | undefined>();
  const [isCompanyEdit, setCompanyEdit] = useState(false);
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    setGlobalLoading(true);
    if (companyId) {
      Api.userCompanies.fetchCompany(companyId).then((response) => {
        setCompany(response);
        setResidentialAddress(
          response.addresses.find((adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL),
        );
        setContactAddress(
          response.addresses.find((adr) => adr.type === CompanyAddressTypeEnum.CONTACT),
        );
        setGlobalLoading(false);
      });
    }
  }, [companyId]);

  const impersonate = useCallback(async () => {
    const response = await Api.userCompanies.impersonateCompany(companyId);
    window.open(response.url, '_blank');
  }, [companyId]);

  const syncWallet = useCallback(
    async (event: any) => {
      event.preventDefault();
      return Api.userCompanies.syncCompanyWallet(companyId).then((response) => {
        setCompany(response);
        success(t('users.sync_wallet_success'));
      });
    },
    [companyId, t],
  );

  const suspend = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.suspend')}
          body={t('users.suspend_body', { name: company?.name })}
          actionText={t('users.suspend')}
          successMessage={t('users.suspend_success')}
          onAction={async (reason) => {
            return Api.userCompanies
              .suspend(companyId, { reason: reason })
              .then((response) => setCompany(response));
          }}
        />,
      );
    },
    [company?.name, companyId, showModal, t],
  );

  const removeSuspension = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ActionModal
          title={t('users.remove_suspension')}
          body={t('users.remove_suspension_body', { name: company?.name })}
          successMessage={t('users.remove_suspension_success')}
          actionText={t('common.submit')}
          onAction={async () => {
            return Api.userCompanies
              .removeSuspension(companyId)
              .then((response) => setCompany(response));
          }}
        />,
      );
    },
    [company?.name, companyId, showModal, t],
  );

  const addAccreditation = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <AddAccreditationDialog
          company={company}
          onAction={async (reason) => {
            return Api.userCompanies
              .addAccreditation(companyId, { reason: reason })
              .then((response) => setCompany(response));
          }}
        />,
      );
    },
    [company, companyId, showModal],
  );

  const addGroupableInvestments = useCallback(
    async (event: any) => {
      event.preventDefault();
      Api.userCompanies.enableGroupableInvestments(companyId).then((response) => {
        success(t('users.enabled_groupable_investments_success'));
        setCompany(response);
      });
    },
    [companyId, t],
  );

  const removeGroupableInvestments = useCallback(
    async (event: any) => {
      event.preventDefault();
      Api.userCompanies.disableGroupableInvestments(companyId).then((response) => {
        success(t('users.disabled_groupable_investments_success'));
        setCompany(response);
      });
    },
    [companyId, t],
  );

  const removeAccreditation = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ReasonModal
          title={t('users.remove_accreditation')}
          body={t('users.remove_accreditation_body', { name: company?.name })}
          successMessage={t('users.remove_accreditation_success')}
          actionText={t('common.remove')}
          onAction={async (reason) => {
            return Api.userCompanies
              .removeAccreditation(companyId, { reason })
              .then((response) => setCompany(response));
          }}
        />,
      );
    },
    [company?.name, companyId, showModal, t],
  );

  const deleteCompany = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <ActionModal
          title={t('users.delete_company')}
          body={t('users.delete_company_body', {
            id: (company?.private_id_prefix ?? '') + (company?.private_id_suffix ?? ''),
          })}
          successMessage={t('common.deleted_success')}
          actionText={t('common.delete')}
          onAction={async () => {
            return Api.userCompanies
              .deleteCompany(companyId)
              .then(() => navigate(RouteList.USERS.LIST));
          }}
        />,
      );
    },
    [showModal, t, company?.private_id_prefix, company?.private_id_suffix, companyId],
  );

  const customNotification = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <FormModal
          title={t('users.send_custom_notification')}
          size={'xl'}
          form={
            <CustomNotificationForm
              onSubmit={(request) => {
                return Api.sharedUsers.sendCustomNotification(request);
              }}
              initial={{
                user_id: companyId,
                user_type: LegalEntityClassEnum.USER_COMPANY,
                notification: {
                  message_types: [],
                  subject: '',
                  message: '',
                },
              }}
              successMessage={t('users.send_custom_notification_success')}
              marketing_settings={company?.marketing_settings ?? []}
            />
          }
        />,
      );
    },
    [company?.marketing_settings, companyId, showModal, t],
  );

  const addAffiliateAmbassador = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <AddAffiliateAmbassadorModal
          title={t('users.add_affiliate_ambassador')}
          body={t('users.add_affiliate_ambassador_body', { name: company?.email })}
          successMessage={t('users.add_affiliate_ambassador_success')}
          actionText={t('common.add')}
          onAction={async (request) => {
            return Api.userCompanies
              .addAffiliateAmbassador(companyId, request)
              .then((response) => setCompany(response));
          }}
        />,
      );
    },
    [showModal, t, company?.email, companyId],
  );

  const assignManager = useCallback(
    async (event: any) => {
      event.preventDefault();
      showModal(
        <AssignManagerModal
          currentManagerId={company?.manager?.id}
          onAction={async (request) => {
            return Api.userCompanies
              .assignManager(companyId, request)
              .then((response) => setCompany(response));
          }}
          title={t('users.assign_manager')}
          body={t('users.assign_manager_modal_body')}
        />,
      );
    },
    [t, showModal, company?.manager?.id, companyId],
  );

  const editRemoveAffiliateAmbassador = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (company?.affiliate_ambassador) {
        showModal(
          <EditAffiliateAmbassadorModal
            title={t('users.edit_affiliate_ambassador')}
            initialValues={company.affiliate_ambassador}
            body={t('users.edit_affiliate_ambassador_body', { name: company?.email })}
            successMessage={t('users.edit_affiliate_ambassador_success')}
            actionText={t('common.save')}
            onAction={async (request) => {
              if (company?.affiliate_ambassador?.id) {
                return Api.userCompanies
                  .editAffiliateAmbassador(companyId, company?.affiliate_ambassador?.id, request)
                  .then((response) => setCompany(response));
              }
            }}
            onDeleteAction={async () => {
              if (company?.affiliate_ambassador?.id) {
                return Api.userCompanies
                  .deleteAffiliateAmbassador(companyId, company?.affiliate_ambassador?.id)
                  .then((response) => setCompany(response));
              }
            }}
          />,
        );
      }
    },
    [showModal, t, company?.affiliate_ambassador, company?.email, companyId],
  );

  const addHighNetWorth = useCallback(
    async (event: any) => {
      event.preventDefault();
      Api.userCompanies.enableHighNetWirth(companyId).then((response) => {
        success(t('users.enabled_high_net_worth_success'));
        setCompany(response);
      });
    },
    [companyId, t],
  );

  const removeHighNetWirth = useCallback(
    async (event: any) => {
      event.preventDefault();
      Api.userCompanies.disableHighNetWirth(companyId).then((response) => {
        success(t('users.disabled_high_net_worth_success'));
        setCompany(response);
      });
    },
    [companyId, t],
  );

  const getTabs = useMemo(() => {
    const newTabs = [...Tabs];
    if (company?.type == UserTypeEnum.INVESTOR) {
      newTabs.splice(
        4,
        0,
        ...[
          {
            name: 'users.investments',
            iconClass: 'fas fa-euro-sign',
            component: TabInvestments,
            permission: Permissions.BO__USERS__VIEW_INVESTMENTS,
          },
          {
            name: 'users.auto_investor_actions',
            iconClass: 'fas fa-robot',
            component: TabAutoInvestorActions,
            permission: Permissions.BO__USERS__VIEW_ACTIONS,
          },
        ],
      );
    }

    return newTabs;
  }, [company]);

  const isInvestor = company?.type == UserTypeEnum.INVESTOR;

  return (
    <React.Fragment>
      <CompanyContext company={company} setCompany={setCompany}>
        <Container fluid>
          <Row>
            <Col md={1} className={'mb-4'}>
              <div>
                <Link to={RouteList.USERS.LIST}>
                  <PrimaryButton title={t('common.back')} type={'button'} />
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className={'mb-4'}>
              <Card className="card card-blue">
                <CardBody>
                  <div className="text-center">
                    <Row>
                      <Col sm={2} className={'flex-center text-light'}>
                        <Row>
                          <CopyOnClick value={company?.id} />
                          <CopyOnClick
                            value={`${company?.private_id_prefix ?? ''}${
                              company?.private_id_suffix ?? ''
                            }`}
                          />
                        </Row>
                      </Col>
                      <Col sm={isInvestor ? 2 : 1} className={'flex-center text-light'}>
                        <Row>
                          <div>{t('users.wallet_balance')}</div>
                          <h3>{t('common.money', { value: company?.wallet_balance })}</h3>
                          {!isInvestor && company && company?.wallet_reservations_amount > 0 && (
                            <div>
                              <div>{t('common.reserved_amount')}</div>
                              <h6>
                                {t('common.money', { value: company?.wallet_reservations_amount })}
                              </h6>
                            </div>
                          )}
                        </Row>
                      </Col>
                      <Col sm={isInvestor ? 3 : 5} className={'flex-center text-light'}>
                        <div>
                          <div>
                            <LegalEntityView entity={company?.legal_entity} />
                          </div>
                          <h2 className="mb-1">
                            {company?.name ?? '-'}
                            {company && company.affiliate_ambassador && (
                              <div className={'my-2 d-flex gap-2 justify-content-center'}>
                                <Badge className={'bg-warning'}>
                                  {t('users.affiliate_ambassador')}
                                </Badge>
                              </div>
                            )}
                            {company && company.investor_properties.is_groupable_investments && (
                              <div className={'my-2 d-flex gap-2 justify-content-center'}>
                                <Badge className={'bg-warning'}>
                                  {t('users.groupable_investments')}
                                </Badge>
                              </div>
                            )}
                            {company && company.investor_properties.is_high_net_worth && (
                              <div className={'my-2 d-flex gap-2 justify-content-center'}>
                                <Badge className={'bg-warning'}>{t('users.high_net_worth')}</Badge>
                              </div>
                            )}
                          </h2>
                          <p>
                            <UserTypeView type={company?.type} />
                          </p>
                        </div>
                      </Col>
                      {isInvestor && (
                        <>
                          <Col sm={2} className={'flex-center text-light'}>
                            <div>
                              <Row>
                                <div>{t('users.historic_investments')}</div>
                                <h3>
                                  {t('common.money', { value: company?.historic_investments })}
                                </h3>
                              </Row>
                              <Row>
                                <div>{t('users.active_investments')}</div>
                                <h3>{t('common.money', { value: company?.active_investments })}</h3>
                              </Row>
                            </div>
                          </Col>
                        </>
                      )}

                      {company?.manager?.name && (
                        <Col sm={1} className={'flex-center text-light'}>
                          <Row>
                            <div>{t('users.manager_name')}</div>
                            <span>{company?.manager?.name}</span>
                          </Row>
                        </Col>
                      )}

                      <Col sm={2} className={'flex-center'}>
                        <div className={'card p-3'}>
                          <h5
                            className={
                              'actions d-flex gap-3 font-size-20 mb-0 justify-content-center'
                            }
                          >
                            <VerifiedBadge verified={!!company?.verified} />
                            <DirectMarketingBadge
                              via_email={!!company?.direct_marketing?.via_email}
                              via_phone={!!company?.direct_marketing?.via_phone}
                            />
                          </h5>
                        </div>
                      </Col>
                      <Col sm={1}>
                        <UncontrolledDropdown className="float-end">
                          <DropdownToggle tag="a" className="cursor text-light font-size-16" caret>
                            <i className="fas fa-ellipsis-h" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            {company && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__ADD_ACCREDITATION}
                              >
                                <DropdownItem href="#" onClick={(e) => addAccreditation(e)}>
                                  {t('users.add_accreditation')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {company && company.affiliate_ambassador === null && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__MANAGE_AFFILIATE_AMBASSADORS}
                              >
                                <DropdownItem href="#" onClick={(e) => addAffiliateAmbassador(e)}>
                                  {t('users.add_affiliate_ambassador')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {company && company.affiliate_ambassador && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__MANAGE_AFFILIATE_AMBASSADORS}
                              >
                                <DropdownItem
                                  href="#"
                                  onClick={(e) => editRemoveAffiliateAmbassador(e)}
                                >
                                  {t('users.edit_remove_affiliate_ambassador')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {company &&
                              isInvestor &&
                              !company.investor_properties.is_groupable_investments && (
                                <PermissionAction
                                  permissions={Permissions.BO__USERS__MANAGE_GROUPABLE_INVESTMENTS}
                                >
                                  <DropdownItem
                                    href="#"
                                    onClick={(e) => addGroupableInvestments(e)}
                                  >
                                    {t('users.allow_groupable_investments')}
                                  </DropdownItem>
                                </PermissionAction>
                              )}
                            {company &&
                              isInvestor &&
                              company.investor_properties.is_groupable_investments && (
                                <PermissionAction
                                  permissions={Permissions.BO__USERS__MANAGE_GROUPABLE_INVESTMENTS}
                                >
                                  <DropdownItem
                                    href="#"
                                    onClick={(e) => removeGroupableInvestments(e)}
                                  >
                                    {t('users.disable_groupable_investments')}
                                  </DropdownItem>
                                </PermissionAction>
                              )}
                            {company &&
                              isInvestor &&
                              !company.investor_properties.is_high_net_worth && (
                                <PermissionAction
                                  permissions={Permissions.BO__USERS__MANAGE_IS_HIGH_NET_WORTH}
                                >
                                  <DropdownItem href="#" onClick={(e) => addHighNetWorth(e)}>
                                    {t('users.add_high_net_worth')}
                                  </DropdownItem>
                                </PermissionAction>
                              )}
                            {company &&
                              isInvestor &&
                              company.investor_properties.is_high_net_worth && (
                                <PermissionAction
                                  permissions={Permissions.BO__USERS__MANAGE_IS_HIGH_NET_WORTH}
                                >
                                  <DropdownItem href="#" onClick={(e) => removeHighNetWirth(e)}>
                                    {t('users.remove_high_net_worth')}
                                  </DropdownItem>
                                </PermissionAction>
                              )}
                            {company && company.verified && (
                              <PermissionAction
                                permissions={Permissions.BO__USERS__REMOVE_ACCREDITATION}
                              >
                                <DropdownItem href="#" onClick={(e) => removeAccreditation(e)}>
                                  {t('users.remove_accreditation')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            {company && !isInvestor && (
                              <PermissionAction
                                permissions={
                                  Permissions.BO__USERS__CHANGE_PROJECT_DEVELOPER_MANAGER
                                }
                              >
                                <DropdownItem href="#" onClick={(e) => assignManager(e)}>
                                  {t('users.assign_manager')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            <PermissionAction permissions={Permissions.BO__USERS__IMPERSONATE}>
                              <DropdownItem href="#" onClick={() => impersonate()}>
                                {t('users.impersonate')}
                              </DropdownItem>
                            </PermissionAction>
                            {company && company.has_primary_wallet && (
                              <PermissionAction permissions={Permissions.BO__USERS__SYNC_WALLET}>
                                <DropdownItem href="#" onClick={(e) => syncWallet(e)}>
                                  {t('users.sync_wallet_balance')}
                                </DropdownItem>
                              </PermissionAction>
                            )}
                            <PermissionAction permissions={Permissions.BO__USERS__SUSPEND}>
                              {company && !company?.suspended_at && (
                                <DropdownItem href="#" onClick={(e) => suspend(e)}>
                                  {t('users.suspend')}
                                </DropdownItem>
                              )}
                              {company && company?.suspended_at && (
                                <DropdownItem href="#" onClick={(e) => removeSuspension(e)}>
                                  {t('users.remove_suspension')}
                                </DropdownItem>
                              )}
                            </PermissionAction>
                            <PermissionAction permissions={Permissions.BO__USERS__DELETE_USER}>
                              <DropdownItem href="#" onClick={(e) => deleteCompany(e)}>
                                {t('common.remove')}
                              </DropdownItem>
                            </PermissionAction>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" className={'mb-4'}>
              <Card className="card h-100">
                <CardBody>
                  <div className="text-muted">
                    <Row className={'justify-content-end'}>
                      <PermissionAction permissions={Permissions.BO__USERS__SEND_NOTIFICATION}>
                        <Col md={2}>
                          <PrimaryButton
                            title={t('users.send_custom_notification')}
                            type={'button'}
                            onClick={(e) => customNotification(e)}
                          />
                        </Col>
                      </PermissionAction>
                      <Col md={2}>
                        <PrimaryButton
                          title={isCompanyEdit ? t('common.cancel_edit') : t('common.edit')}
                          type={'button'}
                          onClick={() => setCompanyEdit((prevState) => !prevState)}
                        />
                      </Col>
                    </Row>
                    <Row className={'mt-4'}>
                      {isCompanyEdit && company && (
                        <CompanyEdit
                          onUpdate={(request) =>
                            Api.userCompanies
                              .updateUserCompany(company.id, request)
                              .then((response) => {
                                setCompany(response);
                                setResidentialAddress(
                                  response.addresses.find(
                                    (adr) => adr.type === CompanyAddressTypeEnum.RESIDENTIAL,
                                  ),
                                );
                                setContactAddress(
                                  response.addresses.find(
                                    (adr) => adr.type === CompanyAddressTypeEnum.CONTACT,
                                  ),
                                );
                              })
                              .then(() => setCompanyEdit(false))
                          }
                          company={company}
                          residentialAddress={residentialAddress}
                          contactAddress={contactAddress}
                        />
                      )}
                    </Row>
                    <h5 className="mb-4">{t('applications.company_details')}</h5>
                    <Row>
                      <SidebarDataRow
                        label={t('users.company.legal_code')}
                        value={company?.legal_code}
                      />
                      <SidebarDataRow
                        label={t('users.company.vat_code')}
                        value={company?.vat_code}
                      />
                      <SidebarDataRow
                        label={t('users.company.established_at')}
                        value={company?.established_at}
                      />
                      <SidebarDataRow
                        label={t('users.company.activity')}
                        value={company?.activity}
                      />
                      <SidebarDataRow label={t('users.email')} value={company?.email} />
                      <SidebarDataRow label={t('users.phone')} value={company?.phone} />
                      <SidebarDataRow
                        label={t('users.company.size')}
                        value={company?.size ? t(`users.company.size.${company.size}`) : ''}
                        isVisible={!!company?.size}
                      />
                      <SidebarDataRow
                        label={t('users.residence')}
                        value={company?.country}
                        displayValue={
                          company?.country ? <CountryLabel code={company?.country} /> : ''
                        }
                      />
                      <SidebarDataRow
                        label={t('users.residential.street')}
                        value={residentialAddress?.street}
                      />
                      <SidebarDataRow
                        label={t('users.residential.address_line')}
                        value={residentialAddress?.address}
                      />
                      <SidebarDataRow
                        label={t('users.residential.city')}
                        value={residentialAddress?.city}
                      />
                      <SidebarDataRow
                        label={t('users.company.representative_title')}
                        value={
                          company?.representative_title
                            ? t(`users.company.${company?.representative_title}`)
                            : ''
                        }
                      />
                      <SidebarDataRow
                        label={t('users.contact.street')}
                        value={contactAddress?.street}
                      />
                      <SidebarDataRow
                        label={t('users.contact.address_line')}
                        value={contactAddress?.address}
                      />
                      <SidebarDataRow
                        label={t('users.contact.city')}
                        value={contactAddress?.city}
                      />
                      <SidebarDataRow
                        label={t('users.executive.is_representative')}
                        value={
                          company?.is_representative_executive ? t('common.yes') : t('common.no')
                        }
                      />
                      <SidebarDataRow
                        label={t('users.company.bank_name')}
                        value={company?.bank_name}
                      />
                      <SidebarDataRow label={t('users.company.iban')} value={company?.iban} />
                      <SidebarDataRow
                        label={t('users.company.management_structure')}
                        value={company?.management_structure}
                        displayValue={t(`users.company.${company?.management_structure}`)}
                      />
                      <SidebarDataRow
                        label={t('users.last_login_at')}
                        isVisible={!!company?.last_login_at}
                        value={t('common.date_full', { date: company?.last_login_at })}
                      />
                      <SidebarDataRow
                        label={t('users.created_at')}
                        isVisible={!!company?.created_at}
                        value={t('common.date_full', { date: company?.created_at })}
                      />
                      <SidebarDataRow
                        label={t('users.suspension_reason')}
                        value={company?.suspension_reason}
                      />
                      <SidebarDataRow
                        label={t('users.suspended_at')}
                        isVisible={!!company?.suspended_at}
                        value={t('common.date_full', { date: company?.suspended_at })}
                      />
                      {isInvestor && (
                        <SidebarDataRow
                          label={t('users.investor_experience_level')}
                          isVisible={!!company?.investor_properties.investor_experience_level}
                          value={
                            company?.investor_properties.investor_experience_level
                              ? t(
                                  'users.investor_experience_level.' +
                                    company?.investor_properties.investor_experience_level,
                                )
                              : '-'
                          }
                        />
                      )}
                      <SidebarDataRow
                        label={t('users.in_aml_monitoring_worklist')}
                        value={
                          company?.in_aml_monitoring_worklist ? t('common.yes') : t('common.no')
                        }
                      />
                    </Row>
                    <hr />
                    <h5 className="mb-4">{t('applications.executive_details')}</h5>
                    <Row>
                      <SidebarDataRow
                        label={t('users.executive.first_name')}
                        value={company?.executive_first_name}
                      />
                      <SidebarDataRow
                        label={t('users.executive.last_name')}
                        value={company?.executive_last_name}
                      />
                      <SidebarDataRow
                        label={t('users.executive.address.house_number')}
                        value={company?.executive_address_house_number}
                      />
                      <SidebarDataRow
                        label={t('users.executive.address.street')}
                        value={company?.executive_address_street}
                      />
                      <SidebarDataRow
                        label={t('users.executive.address.city')}
                        value={company?.executive_address_city}
                      />
                      <SidebarDataRow
                        label={t('users.executive.address.country')}
                        value={company?.executive_address_country}
                        displayValue={
                          company?.executive_address_country ? (
                            <CountryLabel code={company?.executive_address_country} />
                          ) : (
                            ''
                          )
                        }
                      />
                      <SidebarDataRow
                        label={t('users.executive.phone')}
                        value={company?.executive_phone}
                      />
                      <SidebarDataRow
                        label={t('users.executive.email')}
                        value={company?.executive_email}
                      />
                      <SidebarDataRow
                        label={t('users.executive.gov_code')}
                        value={company?.executive_gov_code}
                      />
                      <SidebarDataRow
                        label={t('users.gov_document.type')}
                        value={
                          company?.executive_gov_document_type
                            ? t(`users.document_type.${company?.executive_gov_document_type}`)
                            : ''
                        }
                      />
                      <SidebarDataRow
                        label={t('users.gov_document.number')}
                        value={company?.executive_gov_document_number}
                      />
                      <SidebarDataRow
                        label={t('users.gov_document.issuer_country')}
                        value={company?.executive_gov_document_issue_country}
                        displayValue={
                          company?.executive_gov_document_issue_country ? (
                            <CountryLabel code={company?.executive_gov_document_issue_country} />
                          ) : (
                            ''
                          )
                        }
                      />
                      <SidebarDataRow
                        label={t('users.gov_document.expiration_date')}
                        value={company?.executive_gov_document_expiration_date}
                      />
                      <SidebarDataRow
                        label={t('users.executive.is_pep')}
                        value={company?.executive_is_pep ? t('common.yes') : t('common.no')}
                      />
                      <SidebarDataRow
                        label={t('users.executive.tax_country')}
                        value={company?.executive_tax_country}
                        displayValue={
                          company?.executive_tax_country ? (
                            <CountryLabel code={company?.executive_tax_country} />
                          ) : (
                            ''
                          )
                        }
                      />
                      <SidebarDataRow
                        label={t('users.executive.nationality')}
                        value={company?.executive_nationality}
                        displayValue={
                          company?.executive_nationality ? (
                            <CountryLabel code={company?.executive_nationality} />
                          ) : (
                            ''
                          )
                        }
                      />
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12">
              <Card className="mb-0">
                <Card className="mb-0">
                  {company && (
                    <TabData
                      tabs={getTabs}
                      props={{ id: company.id, company: company }}
                      initialActive={tab}
                    />
                  )}
                </Card>
              </Card>
            </Col>
          </Row>
        </Container>
      </CompanyContext>
    </React.Fragment>
  );
};

export default Company;
